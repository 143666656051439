<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary font-weight-bold"> Carstock </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>

            <b-breadcrumb-item @click="$router.push('/carstock/list')"> Crea Carstock </b-breadcrumb-item>
            <b-breadcrumb-item active>
              Dettagli Carstock
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9">
            <div class="text-primary-light">
            <div><b>NOME:</b> {{carstock_info.nome}}</div>
            <div><b>ID OFFERTA:</b> {{$route.params.id}}</div>
            <div><b>DATA DI FINE: </b>{{carstock_info.data_scadenza}}</div>
            </div>
        </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table
        class="mt-2"
        :fields="fields"
        :items="carstock_vehicles"
        :filter="filter"
        :busy="loading"
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <h5>Non ci sono auto da mostrare</h5>
          </div>
        </template>
        <template #cell(anno)="data">
            {{data.item.immatricolazione_anno}}
        </template>
        <template #cell(prezzo_singolo)="data">
            {{data.item.prezzo_rivenditore}}€
        </template>
        <template #cell(opzioni)>
          <feather-icon
            icon="ListIcon"
            class="cursor-pointer mr-2"
            size="20"
          ></feather-icon>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BBreadcrumb,
  BBreadcrumbItem,
  BTable,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BImg,
    BFormGroup,
    BFormInput,
  },

  data: () => ({
    step: 1,
    carstock_info: [],
    carstock_vehicles: [],
    selected_auto: [],
    carstock_total: 0,
    filter: "",

    fields: [
      {
        key: "id_auto",
        sortable: true,
      },
      {
        key: "marca",
        sortable: true,
      },
      {
        key: "modello",
        sortable: true,
      },
      {
        key: "versione",
        sortable: true,
      },
      {
        key: "anno",
        sortable: true,
      },
      {
          key: "chilometraggio",
          sortable: true,
      },
      {
          key: "prezzo_singolo",
          sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    //Helpers

    loading: false,
  }),

  created() {
    this.getCarStockDetail();
  },

  methods: {
    async getCarStockDetail() {
      this.loading = true;

      let request_data = new FormData()

      request_data.append('id_carstock', this.$route.params.id);

      try {
        const response = await Requests.getCarStockDetail(request_data);
        this.carstock_info = response.db_carstock_details.payload;
        this.carstock_vehicles = response.db_carstock_details.payload.auto_in_stock;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
